.quest-form__notification {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.quest-form__notification_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  padding-bottom: 40px;
  margin-bottom: 10px;
  border: 1px solid #888;
  width: 50%;
}

.quest-form__privacy_policy {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.quest-form__privacy_policy_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid #888;
  width: 50%;
}

.quest-form__notification_close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.quest-form__notification_close:hover,
.quest-form__notification_close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.quest-form__notification_button {
  padding: 6px;
  background-color: #3FA6C6;
  color: white;
  font-weight: bold;
  font-size: large;
}

.quest-form__main {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.quest-form__main input {
  padding: 10px;
  margin: 10px;
}

.quest-form__main label {
  margin-left: 10px;
  margin-bottom: -8px;
}

.quest-form__main button {
  padding: 10px;
  background-color: #3FA6C6;
  color: white;
  font-weight: bold;
  font-size: large;
}

.quest-form__container {
  display: flex;
  justify-content: center;
}

.quest-form___time-container {
  display: flex;
  flex-direction: row;
}

.quest-form___time-main {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.quest-form___time-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quest-form___office-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

.quest-form___consent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 38px;
}

.quest-form___checkbox {
  width: 26px;
  height: 26px;
}

.quest-form___checkbox_label {
  margin-right: 28px;
  height: 26px;
}

.quest-form___checkbox_link {
  margin-right: 28px;
  height: 26px;
  text-decoration: underline;
}

.quest-form___checkbox_link:hover,
.quest-form___checkbox_link:focus {
  cursor: pointer;
}

.quest-form___time-button-container button {
  margin-left: 5px;
}

.loader {
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
