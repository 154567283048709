.app__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.app__header {
  display: flex;
  justify-content: center;
}

.app__flag_container {
  display: flex;
  justify-content: flex-end;
}

.app_punos_logo {
  width: 20%;
  align-self: center;
}